@import './helpers/step-generator.mixin';

@font-face {
  font-family: 'icomoon';
  src: url('~assets/fonts/icomoon.eot?ejcvzk');
  src: url('~assets/fonts/icomoon.eot?ejcvzk#iefix') format('embedded-opentype'),
    url('~assets/fonts/icomoon.ttf?ejcvzk') format('truetype'), url('~assets/fonts/icomoon.woff?ejcvzk') format('woff'),
    url('~assets/fonts/icomoon.svg?ejcvzk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-font_'],
[class*=' icon-font_'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-font_laptop-man:before {
  content: '\e900';
}
.icon-font_cards:before {
  content: '\e901';
}
.icon-font_dashboard:before {
  content: '\e902';
}

.app-container {
  @include step-generator('font-size', 'font-size', 0.25rem, false, true);
}

.mat-h1.title {
  font: 300 26px/32px Raleway;
}
