@use '@angular/material' as mat;
@import 'styles/variables';
@import 'styles/helpers/color-class.mixin';
@import 'styles/theming/palettes';

$rm-colors-map: (
  'accent': map-get($app-colors-map, 'rmt-dark-tint-2')
);

$ql-colors-map: (
  'accent': $ql-accent-palette
);

$ql-rx-colors-map: (
  'accent': $ql-rx-accent-palette
);

$sl-colors-map: (
  'accent': $sl-accent-palette
);

@mixin transparent-background-colors($color-map...) {
  @each $name, $color in keywords($color-map) {
    $c: '';
    @if type-of($color) == color {
      $c: $color;
    } @else {
      $c: mat.get-color-from-palette($color);
    }
    .bg-#{$name}-translucent,
    a.bg-#{$name}-translucent,
    a:link.bg-#{$name}-translucent,
    a:link.bg-#{$name}-translucent > *,
    a.bg-#{$name}-translucent > * {
      background-color: transparentize($c, $bg-transparency);
    }
  }
}

@mixin text-colors($color-map...) {
  @each $name, $color in keywords($color-map) {
    $c: '';
    @if type-of($color) == color {
      $c: $color;
    } @else {
      $c: mat.get-color-from-palette($color);
    }
    .color-#{$name} {
      color: $c;
    }
  }
}

@mixin border-colors($color-map...) {
  @each $name, $color in keywords($color-map) {
    $c: '';
    @if type-of($color) == color {
      $c: $color;
    } @else {
      $c: mat.get-color-from-palette($color);
    }
    .border-#{$name} {
      border-color: $c;
    }
  }
}

.app-container {
  &.sl-theme,
  &.ql-theme,
  &.ql-rx-theme,
  &.default-theme,
  &.rm-theme {
    [class^='border-'],
    [class*=' border-'] {
      border-width: 1px;
      border-style: solid;
      background-color: white;
      color: rgba(0, 0, 0, 0.61);
    }

    @include background-colors($app-colors-map...);
    @include transparent-background-colors($app-colors-map...);
    @include text-colors($app-colors-map...);
    @include border-colors($app-colors-map...);

    .bg-warn {
      background-color: mat.get-color-from-palette($rmt-warn-palette);
      color: $app-white;
    }

    // Override success bg
    .bg-success {
      background-color: map-get($app-colors-map, 'status-green-dark');
      color: $app-white;
    }

    // set the colors for the notifications snack bar
    .mat-mdc-snack-bar-container {
      --mat-mdc-snack-bar-button-color: #{$app-white};
      --mdc-snackbar-supporting-text-color: #{$app-white};

      &.bg-warn {
        --mdc-snackbar-container-color: #{mat.get-color-from-palette($rmt-warn-palette)};
      }

      &.bg-success {
        --mdc-snackbar-container-color: #{map-get($app-colors-map, 'status-green-dark')};
      }

      &.bg-intermediary {
        --mdc-snackbar-container-color: #{map-get($app-colors-map, 'accent-yellow')};
      }

      &.bg-primary-light {
        --mdc-snackbar-container-color: #{map-get($app-colors-map, 'primary-light')};
      }
    }
  }

  &.rm-theme {
    @include background-colors($rm-colors-map...);
  }

  &.sl-theme {
    @include background-colors($sl-colors-map...);
    @include transparent-background-colors($sl-colors-map...);
    @include text-colors($sl-colors-map...);
    @include border-colors($sl-colors-map...);

    .bg-info .action {
      color: mat.get-color-from-palette($sl-accent-palette) !important;
    }
  }

  &.ql-theme {
    @include background-colors($ql-colors-map...);
    @include transparent-background-colors($ql-colors-map...);
    @include text-colors($ql-colors-map...);
    @include border-colors($ql-colors-map...);

    .bg-info .action {
      color: mat.get-color-from-palette($ql-accent-palette) !important;
    }
  }

  &.ql-rx-theme {
    @include background-colors($ql-rx-colors-map...);
    @include transparent-background-colors($ql-rx-colors-map...);
    @include text-colors($ql-rx-colors-map...);
    @include border-colors($ql-rx-colors-map...);

    .bg-info .action {
      color: mat.get-color-from-palette($ql-rx-accent-palette) !important; // For notifications service
    }
  }
}
