@use '@angular/material' as mat;
@import 'styles/variables';

.app-container {
  // variables that are used by mdc to override defaults
  --mdc-icon-button-icon-size: 1.2rem;
  --mdc-icon-button-font-size: 14px;

  // CDK
  .cdk-drag-dragging {
    .hide-on-drag {
      display: none;
    }
  }

  // Tabs
  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs {
    .mat-mdc-tab-link {
      flex-grow: 0;
      min-width: 160px;
    }
  }
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header {
    .mat-mdc-tab {
      flex-grow: 0;
      min-width: 160px;
    }
  }

  //// REMOVE: other app uses bold
  //.mat-mdc-tab-link:not(.mat-mdc-tab-label-active) {
  //  font-weight: normal;
  //}

  // Forms and definitions
  //.mat-mdc-select-trigger {
  //  width: 100%;
  //}
  //
  //.mat-mdc-select-panel {
  //  height: 20rem;
  //}
  //
  //.mat-mdc-select-panel .mat-mdc-option {
  //  height: 2rem;
  //}
  //
  //mat-mdc-form-field {
  //  width: 100%;
  //}
  //
  //.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  //  margin: 0;
  //}
  //
  //.mat-mdc-snack-bar-container {
  //  word-break: break-all;
  //}
  //
  //.mat-mdc-select-panel {
  //  height: 20rem;
  //}
  //
  mat-option {
    min-height: 2rem;
    width: auto !important;
  }

  .mat-mdc-form-field {
    width: 100%;
    .dense {
      line-height: 1.75;
    }
  }
  //
  //.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  //  margin: 0;
  //}
  //
  //.mat-mdc-chip-list-wrapper {
  //  margin: 0;
  //}

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: map-get($app-colors-map, 'light-gray');
  }

  // Stepper
  mat-horizontal-stepper {
    border-radius: 0.25rem;
  }

  // Icons
  .mat-icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }

  fa-icon {
    line-height: 24px;
  }

  .mat-mdc-menu-panel {
    max-height: 400px;
    margin-top: 0;

    .mat-mdc-menu-item .mat-mdc-menu-item-text {
      white-space: nowrap;
    }
  }

  .mat-mdc-menu-item:not[disabled] {
    color: mat.get-color-from-palette($app-foreground, text);

    &:hover {
      text-decoration: none;
      color: mat.get-color-from-palette($app-foreground, text);
    }
  }

  // Buttons
  .mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: inherit;
  }

  .mat-mdc-raised-button.mat-unthemed,
  .mat-unthemed.mat-mdc-flat-button {
    --mdc-protected-button-label-text-color: #{map-get($app-colors-map, 'dark-text')};
    color: map-get($app-colors-map, 'rmt-dark-tint-1');
  }

  .mat-mdc-unelevated-button {
    --mdc-filled-button-label-text-color: #{map-get($app-colors-map, 'rmt-dark-tint-1')};
  }

  .mdc-button-focus-overlay {
    background-color: transparent !important;
  }

  .mat-mdc-raised-button {
    color: map-get($app-colors-map, 'rmt-dark-tint-1');
    height: 32px !important;
    .mat-button-wrapper {
      span {
        vertical-align: baseline;
      }
      fa-icon {
        vertical-align: unset;
      }
    }
  }

  .mat-mdc-raised-button.ternary-button {
    color: map-get($app-colors-map, 'rmt-dark-tint-1') !important;
    background-color: white !important;
    box-shadow: none;

    &.active {
      box-shadow: none;
    }
  }

  // Other
  .mat-mdc-divider {
    // This resolves issue of divider disappearing when elements around it use margins
    border-top-width: 2px;
  }

  mat-form-field {
    line-height: 1.5;
  }

  mat-progress-bar {
    height: 16px;
    border-radius: 0.25rem;
  }

  // This ensures that data-table action columns take up the minimum width they need
  .mat-mdc-column-actions {
    width: 0.1%;
    min-width: 3rem;
    white-space: nowrap;
  }

  .mat-mdc-button > .mat-icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }

  .mat-mdc-icon-button {
    height: 2rem;
    width: 2rem;
    font-size: 14px;
    line-height: 2rem;
    padding: 0;
    &.raised {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      transition: color ease-in-out 300ms, background-color ease-in-out 300ms;
      &.rmt-primary {
        color: $app-white;
        background: map-get($app-colors-map, 'rmt-dark');
      }
    }

    // in the select and multiselect search box with ngx-mat-select-search
    &.mat-select-search-clear {
      top: 0.3rem;
    }

    svg {
      vertical-align: middle;
    }
  }

  // MatTable
  .mat-mdc-header-cell {
    font-size: 12px;
    color: $app-dark-text;
  }

  tr.mat-mdc-footer-row,
  tr.mat-mdc-row {
    height: 2.25rem;
  }

  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mat-mdc-form-field-label {
    transform: translateY(-1em) scale(0.75);
  }

  // MatButton
  .mdc-button,
  .mat-mdc-menu-item,
  .mat-mdc-stroked-button,
  .mat-mdc-raised-button,
  .mat-mdc-flat-button,
  .mat-mdc-icon-button,
  .mat-mdc-button-toggle .mat-button-toggle-label-content,
  .mat-mdc-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 2rem;
    height: 2rem;
  }

  .mat-mdc-raised-button[disabled].mat-mdc-button-disabled,
  .mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
    color: var(--mdc-protected-button-disabled-label-text-color);
    background-color: var(--mdc-protected-button-disabled-container-color);
  }

  // remove the ripple effect after a button is clicked
  .mat-mdc-button-persistent-ripple {
    display: none;
  }

  .mat-mdc-icon {
    font-size: 0.75rem;
    width: 1rem;
    height: 1rem;
  }

  // MatFormField
  .mat-mdc-form-field:not(.mat-form-field-appearance-legacy) .mat-mdc-form-field-prefix .mat-mdc-icon-button,
  .mat-mdc-form-field:not(.mat-form-field-appearance-legacy) .mat-mdc-form-field-suffix .mat-mdc-icon-button {
    font-size: 0.75rem;
    width: 1.5em;
    height: 1.5em;
  }

  .mat-mdc-form-field-suffix .mat-mdc-icon {
    font-size: 1rem;
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-wrapper {
      margin: 0;
      padding-bottom: 0;
    }

    .mat-mdc-form-field-infix {
      padding: 2px;
    }

    .mat-mdc-form-field-icon-suffix > .mat-icon {
      padding: 0 !important;
    }

    input.mat-mdc-input-element {
      margin-top: 0.125rem;
    }

    .mat-mdc-select {
      margin-top: 0.9rem;
    }

    //.mat-mdc-form-field-underline {
    //  bottom: 0.375rem;
    //}
    //
    //.mat-mdc-form-field-subscript-wrapper {
    //  top: calc(100% - 0.625rem);
    //}
    //
    //.mat-mdc-form-field-error-wrapper {
    //  padding: 0 5px;
    //}
  }

  .mat-mdc-snack-bar-container {
    border-radius: 5px;
  }

  // outline fill layout styles
  mat-form-field.mat-form-field-appearance-outline {
    margin-bottom: 1rem;

    .mdc-text-field {
      padding-left: 0.5rem;
    }

    textarea {
      padding-top: 0.25rem;
    }
  }
  .mat-form-field-appearance-outline {
    input.mat-mdc-input-element,
    .mat-mdc-select {
      margin-top: 0.9rem;
    }
  }

  // appearance fill layout styles
  .mat-form-field-appearance-fill {
    input.mat-mdc-input-element {
      margin-top: 2rem;
    }

    .mat-mdc-select {
      margin-top: 1.8rem;
      --mat-select-disabled-trigger-text-color: #{map-get($app-colors-map, 'dark-gray')};
    }
  }

  // change how all the toggles look
  .mat-mdc-slide-toggle {
    // the color of the unselected toggle
    --mdc-switch-unselected-handle-color: #{$app-white};
    --mdc-switch-unselected-icon-color: #{map-get($app-colors-map, 'medium-gray')};
    --mdc-switch-unselected-hover-handle-color: #{map-get($app-colors-map, 'light-gray')};
    --mdc-switch-unselected-focus-handle-color: #{map-get($app-colors-map, 'light-gray')};

    // the color of the disabled, unselected toggle
    --mdc-switch-disabled-unselected-handle-color: #{map-get($app-colors-map, 'light-gray')};
    --mdc-switch-disabled-unselected-icon-color: #{map-get($app-colors-map, 'medium-gray')};

    // keep the disabled from making the text too light, just keep the color what was inherited
    .mdc-switch--disabled + label {
      color: unset;
    }
  }

  // dense form field layout styles
  .dense {
    .mat-mdc-form-field-infix {
      padding: 2px;
      min-height: 28px;
    }

    .mat-mdc-form-field {
      line-height: 1.75;
      margin-bottom: 0rem;
      mat-icon {
        font-size: 1rem;
        width: 1.25rem;
        height: 1rem;
      }

      .mat-mdc-floating-label {
        font-size: 16px;
        top: 15px;
        &.mdc-floating-label--float-above {
          transform: translateY(-1.5em) scale(0.75);
        }
      }

      label.mdc-floating-label {
        .mdc-floating-label--float-above {
          transform: translateY(-2em) scale(0.75);
        }
      }
    }
    &.mat-mdc-button,
    &.mat-mdc-stroked-button,
    &.mat-mdc-raised-button,
    &.mat-mdc-flat-button,
    &.mat-mdc-icon-button,
    &.mat-mdc-button-toggle .mat-mdc-button-toggle-label-content,
    &.mat-mdc-button-toggle-appearance-standard .mat-mdc-button-toggle-label-content {
      line-height: 1rem;
      height: 1rem;
    }

    &.mat-mdc-icon-button {
      width: 1rem;
      height: 1rem;
    }

    &.mat-mdc-form-field-suffix .mat-mdc-icon {
      font-size: 1rem;
    }

    &.mat-mdc-icon {
      font-size: 0.75rem;
      width: 1rem;
      height: 1rem;
    }

    &.mat-mdc-form-field {
      line-height: 1.75;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border: none !important;
      }
    }

    input.mat-mdc-input-element,
    .mat-mdc-select {
      margin-top: 0.125rem;
    }

    .mdc-text-field {
      --mat-form-field-state-layer-color: #{$app-white}; // background color of fields when cursor active
      --mdc-filled-text-field-container-color: #{$app-white}; // background color when not active
      --mdc-filled-text-field-disabled-container-color: #{$app-white}; // background color when disabled
      border-top-left-radius: 0px;
      border-bottom-right-radius: 4px;
      padding-left: 8px; // less padding in the input field at the left side
    }
    .mat-mdc-chip {
      --mdc-chip-elevated-disabled-container-color: #{map-get($app-colors-map, 'light-gray')};
      --mdc-chip-container-height: 1.4rem;
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }

  //mat-card-actions,
  //.mat-mdc-card-actions,
  //[mat-card-actions] {
  //  &.justify-end {
  //    display: grid;
  //    *:first-child {
  //      margin-left: auto;
  //    }
  //  }
  //}
  //
  //mat-chip.justify-center {
  //  justify-content: center;
  //}

  // the business wants to use a different 'primary' color than the
  // actual primary rmt purple so the palette model doesn't
  // quite work.  This overrides and sets the style when using
  // color="primary" on an element

  button.mat-primary:not([disabled]) {
    color: white;
    background-color: map-get($app-colors-map, 'rmt-dark-tint-1');
  }
}
