@import 'styles/variables';

.workspace-panel-wrapper {
  display: grid;
  grid-template-columns: auto minmax(300px, 1fr);
  grid-gap: 0.5rem;

  &.minimize-queue {
    grid-template-columns: auto 1fr;
  }
}

.workspace-panel-wrapper-modal {
  background: map-get($app-colors-map, 'extra-light-gray');
  height: 100%;
  padding: 0.1rem;
  overflow-x: scroll;
}

.workspace-header-btn fa-icon {
  color: $app-dark-text;
}

.workspace-queue-panel {
  max-width: 55vw;
}

.clear-all-filter-options {
  color: map-get($app-colors-map, 'rmt-dark');
}

.workspace-kpi-container {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(180px, 220px));
}

.empty-display-card-layout {
  text-align: center;
  > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.content-container {
  display: grid;
  grid-gap: 1rem;
}

.workspace-header-label-wrapper {
  margin-bottom: 0.25rem;
}

.workspace-header-label {
  font-size: 24px;
  font-weight: 500;
}

.workspace-subheader {
  margin-top: -0.5rem;
}

.workspace-display-card-label {
  display: flex;
  flex-flow: row wrap;
  > * {
    margin: 0.25rem;
  }
  margin-left: -0.25rem;
}

.workspace-reason-info-icon {
  position: relative;
  top: 0.25rem;
  font-size: 1.3rem;
  padding-right: 0.25rem;
}

.workspace-display-card-label-no-icon {
  display: flex;
  flex-flow: row wrap;
  > * {
    margin: 0.25rem;
  }
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin-bottom: 0rem;
}

.record-details-header {
  background: map-get($app-colors-map, 'extra-light-gray');
  border-radius: 0.5rem;
}

.header-title {
  border-radius: 0.5rem 0.5rem 0 0;
}

.header-data-section {
  background: map-get($app-colors-map, 'extra-light-gray');
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.workspace-item-display {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.info-display-wrapper {
  grid-template-columns: min-content max-content;
  grid-row-gap: 0.4rem;
  grid-column-gap: 2rem;
  white-space: nowrap;
  align-items: center;
}

.header-action-button.mat-mdc-raised-button {
  padding: 0;
  min-width: 36px;
}
