@use '@angular/material' as mat;
@import 'color-contrast.function';

@mixin background-colors($color-map...) {
  @each $name, $color in keywords($color-map) {
    $c: '';
    @if type-of($color) == color {
      $c: $color;
    } @else {
      $c: mat.get-color-from-palette($color);
    }
    .bg-#{$name},
    a.bg-#{$name},
    a:link.bg-#{$name},
    a:link.bg-#{$name} > *,
    a.bg-#{$name} > * {
      background-color: $c;
      color: choose-contrast-color($c);
      @if type-of($color) != color {
        &.dark {
          background-color: mat.get-color-from-palette($color, 900);
          color: white;
        }
        &.light {
          background-color: mat.get-color-from-palette($color, 200);
          color: mat.get-contrast-color-from-palette($color, 200);
        }
      }
    }
  }
}
