// WRAPPER container elements; cards, headers, footers
@mixin step-generator($properties, $property-prefix, $start_size: 0.25rem, $auto: false, $important: false) {
  $steps: 6;

  @for $i from 1 through $steps {
    .#{$property-prefix}-#{$i} {
      @each $property in $properties {
        @if $important {
          #{$property}: $start_size !important;
        } @else {
          #{$property}: $start_size;
        }
      }
      $start_size: $start_size * 2;
    }
  }

  // Zero
  .#{$property-prefix}-0 {
    @each $property in $properties {
      @if $important {
        #{$property}: 0 !important;
      } @else {
        #{$property}: 0;
      }
    }
  }

  @if $auto {
    .#{$property-prefix}-auto {
      @each $property in $properties {
        #{$property}: auto;
      }
    }
  }
}

// Creates steps with top, left, bottom, and right suffixes
@mixin compassed-step-generator($property, $property-prefix, $start_size: 0.25rem, $auto: false) {
  @include step-generator(($property), $property-prefix, $start_size, $auto);
  // top
  @include step-generator((#{$property}-top), #{$property-prefix}t, $start_size, $auto);
  // bottom
  @include step-generator((#{$property}-bottom), #{$property-prefix}b, $start_size, $auto);
  // left
  @include step-generator((#{$property}-left), #{$property-prefix}l, $start_size, $auto); // deprecate this syntax
  @include step-generator((#{$property}-left), #{$property-prefix}s, $start_size, $auto); // Migrate to this syntax
  // right
  @include step-generator((#{$property}-right), #{$property-prefix}r, $start_size, $auto); // deprecate this syntax
  @include step-generator((#{$property}-right), #{$property-prefix}e, $start_size, $auto); // Migrate to this syntax
  // vertical
  @include step-generator((#{$property}-top, #{$property}-bottom), #{$property-prefix}v, $start_size, $auto);
  // horizontal
  @include step-generator((#{$property}-right, #{$property}-left), #{$property-prefix}h, $start_size, $auto);
}
