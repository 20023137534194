@import 'styles/variables';
@import 'styles/sizing.variables';

.rmt-dialog-container {
  position: relative;
}

// CUSTOM DIALOG STYLES
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: rgba(0, 0, 0, 0.62);
  }
  &.light-backdrop {
    background: rgba(0, 0, 0, 0.32);
  }
}

.app-container .mat-mdc-dialog-container,
.app-container .popover-content {
  position: relative;
  max-width: $max-dialog-width !important;
  padding: 0;
  overflow: hidden;
  background: $app-white;
}

.app-container .popover-content:not(.popover-is-template) > *,
.app-container .popover-content.popover-is-template {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: unset;
  padding: 0;
  height: 100%;
  grid-template-areas:
    'cardHeader'
    'cardContent'
    'cardActions';
}

.app-container .dialog-sidebar .mat-mdc-dialog-container {
  max-height: fit-content;
  max-width: fit-content;

  app-vertical-stepper {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: min-content;
    padding: 0;
    height: 100%;
    grid-template-areas:
      'cardSideBar cardHeader'
      'cardSideBar cardContent'
      'cardSideBar cardActions';
  }

  .mat-mdc-dialog-content {
    max-height: 100%;
    max-width: 100%;
  }

  .mdc-dialog__container {
    align-items: unset;
    display: contents;
    max-height: fit-content;
    max-width: fit-content;
  }

  .mdc-dialog__surface {
    display: contents;
  }
}

.popover-content > * {
  max-height: 400px;
}
app-dialog-content {
  > form,
  > app-field-renderer,
  > app-form {
    min-width: 350px;
  }
}

.app-container .cdk-overlay-pane {
  max-height: $max-dialog-height;
  max-width: $max-dialog-width;
}
