@import 'styles/variables';

// Breadcrumbs and titles
.breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;

  font-size: 0.875rem;

  color: map-get($app-colors-map, 'dark-gray');

  li {
    display: inline;
    padding: 0 0.25rem;
    float: left;

    a {
      &:link,
      &:hover,
      &:visited,
      &:active {
        color: map-get($app-colors-map, 'dark-gray');
        margin-left: 2px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    //&:after {
    //  font-family: 'Font Awesome 5 Pro';
    //  content: '\f054';
    //  font-weight: 900;
    //  font-size: 80%;
    //  margin-left: 5px;
    //}

    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }

    &:last-child {
      color: black;

      mat-icon {
        color: black;
      }

      a {
        &:link,
        &:hover,
        &:visited,
        &:active {
          color: black;
        }
      }
      //
      //&:after {
      //  content: '';
      //}
    }
  }
}
