@import 'styles/variables';

$spacer: 1rem;

.rmt-card {
  padding: $spacer;
  border-radius: $app-border-radius;
  background-color: $app-white;
}

.rmt-container {
  border-radius: $app-border-radius;
  background-color: $app-white;
  .rmt-section {
    background-color: $app-white;
  }
}

.rmt-large-subsection-header {
  background-color: #817b99 !important;
  color: $app-white !important;
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
  padding: 1.5rem;
  border-radius: 0.25rem;
  height: 4rem;
  font-size: 1.5rem;
  @extend .mat-typography;
  .rmt-subsection-header-label {
    margin-bottom: 0;
  }
  &.gray {
    background-color: map-get($app-colors-map, 'light-gray') !important;
  }
}

.rmt-subsection-header {
  background-color: #817b99 !important;
  color: $app-white !important;
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  @extend .mat-typography;
  .rmt-subsection-header-label {
    margin-bottom: 0;
  }

  .rmt-subsection-header-actions {
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0;
    --mdc-text-button-label-text-color: #{$app-white};
  }
}
