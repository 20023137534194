@import 'variables';

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip.status-pill {
  padding: 0;

  .status-text {
    min-width: 7rem;
    text-align: center;
  }
}

.status-pill {
  min-width: 7rem;
  width: 100%;
  border-radius: 20px;
  padding: 6px;
  text-align: center;
  font-size: 0.85rem;
  cursor: default;
  border: 1px solid;

  &.NEW,
  &.UNDERWRITING {
    background: white;
    border-color: map-get($app-colors-map, 'medium-gray');
  }

  &.AWARDED,
  &.BOUND,
  &.DRAFTING,
  &.SENT_TO_MARKET,
  &.SHORTLIST,
  &.SUBMITTED,
  &.WON {
    background: map-get($app-colors-map, 'rmt-violet-tint-3');
    border-color: map-get($app-colors-map, 'rmt-violet-tint-3');
  }

  &.CLOSED,
  &.LOST,
  &.DECLINED {
    background: map-get($app-colors-map, 'light-gray');
    border-color: map-get($app-colors-map, 'light-gray');
  }

  &.clickable {
    cursor: pointer;
  }

  &.completed {
    background: map-get($app-colors-map, 'rmt-dark-tint-3') !important;
    border-color: map-get($app-colors-map, 'rmt-dark-tint-3') !important;
  }

  &.future {
    background: white !important;
    border-color: map-get($app-colors-map, 'light-gray') !important;
    color: map-get($app-colors-map, 'light-gray') !important;
  }
}
