@use "sass:math";
@import 'styles/variables';
@import './helpers/step-generator.mixin';

.app-container {
  @mixin grid-generator() {
    $sizes: 2, 3, 4, 6, 12;

    @each $size in $sizes {
      .grid-columns-#{$size} {
        grid-template-columns: repeat($size, minmax(1px, 1fr));
        grid-auto-flow: unset !important; // This overrides grid-content auto flow
      }
    }
  }

  @include compassed-step-generator('padding', 'p');
  @include compassed-step-generator('margin', 'm', 0.25rem, true);
  @include step-generator('grid-gap', 'grid-gap');

  $screen-activations: (
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'lt-sm',
    'lt-md',
    'lt-lg',
    'lt-xl',
    'gt-xs',
    'gt-sm',
    'gt-md',
    'gt-lg'
  );

  @each $activation in $screen-activations {
    @for $i from 1 through 12 {
      &.ss-#{$activation} .span-#{$activation}-#{$i} {
        @extend %styling-#{$i};
      }
    }
  }
  // Span regardless of activation
  @each $activation in $screen-activations {
    @for $i from 1 through 12 {
      &.ss-#{$activation} .span-#{$i} {
        @extend %styling-#{$i};
      }
    }
  }

  $spacing: 0.625rem;

  .flex-layout {
    display: flex;
    flex-flow: row wrap;
    padding: 0.25rem;
    > * {
      margin: 0.25rem;
    }
  }

  .grid-layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: $spacing;
  }

  @for $i from 1 through 12 {
    %styling-#{$i} {
      $var: percentage(math.div($i, 12));
      flex: 1 1 calc(#{$var} - #{$spacing});
      min-width: calc(#{$var} - #{$spacing});
      grid-column: span #{$i};
    }
  }

  // grid helpers
  .grid,
  [class^='grid-'],
  [class*=' grid-'] {
    display: grid;
  }

  .grid-container {
    grid-template-columns: minMax(1px, 1fr);
    grid-gap: 0.5rem;
  }

  .horizontal-scroll {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .horizontal-scroll-flex {
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: row;
    display: flex;
    // Commented out because it breaks scrolling for Windows user
    //-ms-overflow-style: none; __<<ngThemingMigrationEscapedComment0>>__
    //scrollbar-width: none; __<<ngThemingMigrationEscapedComment1>>__
    //&::-webkit-scrollbar {
    //  display: none;
    //}
    > * {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
    > .preserve-box-shadow,
    &.preserve-box-shadow {
      padding-bottom: 0.5rem;
      margin: 0;
    }
  }

  .preserve-box-shadow {
    margin: -0.25rem;
    padding: 0.25rem;
  }

  .hide-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }

  // CONTENT container elements; buttons, form-definitions
  .grid-content {
    grid-auto-flow: column;
    grid-column-gap: 0.25rem;
  }

  .grid-auto-fit {
    grid-template-columns: repeat(auto-fit, minMax(120px, 1fr));
  }

  .flex,
  [class^='flex-'],
  [class*=' flex-'] {
    display: flex;
  }

  .flex-content {
    display: flex;
    flex-flow: row;
    &.wrap {
      flex-wrap: wrap;
    }
    > * {
      margin: 0 0.5rem 0.5rem 0;
      &:last-child {
        margin: 0 0 0.5rem 0;
      }
    }
  }
  .flex-grow {
    flex: 1;
  }

  $spacing: 1rem;

  .divide-right {
    border-right: $app-border-props;
    padding-right: $spacing;
    margin-right: $spacing;
  }
  .divide-left {
    border-left: $app-border-props;
    padding-left: $spacing;
  }

  .align-start {
    align-items: start;
  }

  .align-center {
    align-items: center;
  }

  .align-stretch {
    align-items: stretch;
  }

  .align-end {
    align-items: end;
  }

  .align-stretch {
    align-items: stretch;
  }

  .align-self-start {
    align-self: start;
  }

  .align-self-center {
    align-self: center;
  }

  .justify-start {
    justify-items: start;
    justify-content: flex-start;
  }

  .justify-center {
    justify-items: center;
    justify-content: center;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-end {
    justify-items: end;
    justify-content: flex-end;
  }

  .justify-self-center {
    justify-self: center;
  }

  .justify-self-end {
    justify-self: end;
  }

  .justify-self-start {
    justify-self: start;
  }

  // TEXTUAL elements; p tags, info text
  // TODO: make a directive to apply wrapper for this style and text content that should go inside.
  //  This could have a typed settings input that takes values such as 'information', 'text', or 'data'
  //  that would cause the directive to adjust the styles as appropriate to ensure the text always remains
  //  with in the 50-60 character happy-place, while still adjusting font-size as appropriate.
  .information {
    max-width: 460px;
    text-align: center;
    font-size: 1.25rem;
    padding: 0.25rem;
  }

  // DELIMITERS
  .vertical-divider,
  [class^='vertical-divider-'],
  [class*=' vertical-divider-'] {
    position: relative;
    &:last-child {
      &:after {
        content: unset;
      }
    }
    &:after {
      content: ' ';
      border-right: 2px solid rgba(0, 0, 0, 0.12);
      position: absolute;
      right: calc(-0.5rem - 1px);
      top: 0;
      bottom: 0;
    }
  }

  $start_size: 0.25rem;
  @for $i from 1 through 6 {
    .vertical-divider-#{$i} {
      &:after {
        right: -#{$start_size};
      }
      $start_size: $start_size * 2;
    }
  }

  @include grid-generator(); // Place near bottom to ensure that these rules take precedence
}

.rmt-toolbar {
  display: grid;
  grid-gap: 0.25rem;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  padding: 0;
}
