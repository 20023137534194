@use "sass:math";
@import 'linear-channel.constant';
@import '../variables';

/**
 * Calculate the luminance for a color.
 * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 */
@function luminance($color) {
  $red: nth($linear-channel-values, red($color) + 1);
  $green: nth($linear-channel-values, green($color) + 1);
  $blue: nth($linear-channel-values, blue($color) + 1);

  @return 0.2126 * $red + 0.7152 * $green + 0.0722 * $blue;
}

/**
 * Calculate the contrast ratio between two colors.
 * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 */
@function contrast($back, $front) {
  $backLum: luminance($back) + 0.05;
  $foreLum: luminance($front) + 0.05;

  @return math.div(max($backLum, $foreLum), min($backLum, $foreLum));
}

/**
 * Determine whether to use dark or light text on top of given color.
 * Returns black for dark text and white for light text.
 */
@function choose-contrast-color($color) {
  $lightContrast: contrast($color, white);
  $darkContrast: contrast($color, $app-dark-text);

  @if ($lightContrast > $darkContrast) {
    @return white;
  } @else {
    @return $app-dark-text;
  }
}
