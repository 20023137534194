@import 'styles/variables';

.dashboard-container {
  .row {
    margin-bottom: 32px;
  }
}

.card-chart {
  height: 100%;

  .card-info-icon {
    color: map-get($app-colors-map, 'medium-gray');
    position: absolute;
    top: 3px;
    right: 7px;
    cursor: pointer;
  }
}
