@use '@angular/material' as mat;
@import 'styles/theming/palettes/rmt-warn.palette';

.persistent-error {
  border: solid 1px mat.get-color-from-palette($rmt-warn-palette, 500);
  color: mat.get-color-from-palette($rmt-warn-palette, 500);
  background-color: mat.get-color-from-palette($rmt-warn-palette, 50);
  margin: 0 auto 15px;
  height: 50px;
  border-radius: 5px;
  p {
    margin: 0;
  }
  i,
  fa-icon {
    min-width: 2rem;
    text-align: center;
  }
}
