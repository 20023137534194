@use '@angular/material' as mat;
@import 'styles/variables';

.btn-link {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: transparent;
    color: map-get($app-colors-map, 'rmt-dark');
    box-shadow: none;
  }
  &.apply {
    color: map-get($app-colors-map, 'rmt-dark');
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: map-get($app-colors-map, 'rmt-dark');
    }
  }
}

.btn-reset {
  font-size: inherit;
  font-family: inherit;
  border: none;
  padding: 0;
  background: none;
}

.btn-reset:not([disabled]) {
  cursor: pointer;
}

.sl-theme .btn-accent {
  background-color: mat.get-color-from-palette($sl-accent-palette);
}

.ql-theme .btn-accent {
  background-color: mat.get-color-from-palette($ql-accent-palette);
}

.ql-rx-theme .btn-accent {
  background-color: mat.get-color-from-palette($ql-rx-accent-palette);
}

.rm-theme .btn-accent {
  background-color: mat.get-color-from-palette($rm-accent-palette);
}

.app-container .btn-anchor {
  text-decoration: underline;
  color: $app-link-text;
  font-family: 'Raleway', sans-serif;
  font-size: 0.75rem;

  &[disabled] {
    color: map-get($app-colors-map, 'medium-gray');
  }
}
