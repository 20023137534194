@use '@angular/material' as mat;
@use 'sass:meta';
@import 'styles/theming/theming.variables';

$mat-theme-ignore-duplication-warnings: true;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-themes($app-theme-light);
@include mat.core();
@include mat.all-component-typographies($app-theme-light);

// We only need to set a theme for sl and ql-rx, the default will be ql
.sl-theme {
  @include mat.all-component-colors($sl-theme-light);
  @include meta.load-css('styles/buttons');
}

.ql-rx-theme {
  @include mat.all-component-colors($ql-rx-theme-light);
  @include meta.load-css('styles/buttons');
}

.rm-theme {
  @include mat.all-component-colors($rm-theme-light);
  @include meta.load-css('styles/buttons');
}
