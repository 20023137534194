@use '@angular/material' as mat;
@import 'styles/variables';

.app-container {
  // site wide filter styles
  .site-filter-container {
    .dense-menu {
      .mat-tab-header {
        height: 100%;
        border-bottom: 1px solid map-get($app-colors-map, 'light-gray');
      }
    }

    .mat-mdc-form-field {
      width: auto !important;
      margin-bottom: 0rem;
    }

    .mat-mdc-select {
      --mdc-typography-body1-line-height: 18px;
      margin-top: 0.125rem;
    }

    .mat-mdc-select-value {
      max-width: 100%;
      width: auto;
    }

    .mat-mdc-select-value-text {
      font-size: 16px;
    }

    .mat-mdc-select-arrow-wrapper {
      padding-left: 0.3rem;
    }

    .mat-mdc-form-field-infix {
      width: auto !important;
    }

    .site-filter-select-panel {
      transform: translateY(0);
    }

    .mat-form-field-appearance-outline {
      background: $app-white;
      overflow: hidden;
      border-radius: 5px;
    }

    .mat-mdc-text-field-wrapper {
      padding-left: 0.5rem;
      border: none !important;
      height: 30px;
      font-size: 16px;
    }

    .mat-end-date {
      top: -2.3px;
      padding-left: 0.4rem;
    }

    .site-filter-has-selected-options {
      .mat-mdc-select-trigger,
      .mat-mdc-text-field-wrapper {
        background: map-get($app-colors-map, 'primary-light');
      }
    }

    .mat-mdc-text-field-wrapper:hover {
      border: none !important;
      -webkit-backface-visibility: hidden;
    }
  }

  .site-filter-select-panel {
    padding-bottom: 0;
    height: 15rem;
    mat-option {
      min-height: 2rem;
      width: auto;
    }

    .mat-mdc-select-panel {
      max-height: 30rem;
    }

    .mat-select-search-input {
      border-bottom: 1px solid gray;
    }

    &.mdc-menu-surface--open {
      display: inline-table;
    }

    cdk-virtual-scroll-viewport {
      height: 15rem;
    }
  }

  app-site-filter-date-range-field {
    mat-date-range-input {
      display: inline-block;
      width: 180px !important;
    }

    .mat-date-range-input-container {
      margin-left: 9px;
    }

    .mat-date-range-input-separator {
      position: relative;
      left: 2px;
    }

    .mat-date-range-input-end-wrapper {
      position: relative;
      top: 2px;
      left: -5px;
    }

    .mat-form-field-appearance-outline {
      background: map-get($app-colors-map, 'primary-light') !important;
    }
  }
}
