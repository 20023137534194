@import 'styles/variables';

.mat-mdc-header-cell.mat-column-rowSelect .mat-sort-header-button {
  margin: auto;
}

.mat-mdc-row {
  &.odd {
    background-color: map-get($app-colors-map, 'extra-light-gray');
  }

  &.selectable-row {
    cursor: pointer;
  }

  &:not(.selected-row) {
    &:hover {
      background: map-get($app-colors-map, 'rmt-dark-tint-3');
    }

    &:active {
      background: map-get($app-colors-map, 'rmt-dark-tint-3');
    }
  }
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

app-data-table-header {
  // buttons for the data tables
  --mdc-icon-button-icon-size: 0.9rem;
}

.app-container {
  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-page-size {
      display: grid;
      grid-template-columns: auto auto;
    }
    .mdc-text-field {
      width: 5rem;
      --mdc-filled-text-field-container-color: #{$app-white};
    }
  }

  app-data-table {
    .mat-column-actions {
      .mat-mdc-button-disabled {
        color: var(--mdc-icon-button-disabled-icon-color);
      }
    }
  }
}
