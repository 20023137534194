@import 'styles/theming/theming.variables';

$black-text-opacity: 0.79;
$bg-transparency: 0.83;

$app-colors-map: (
  // legacy keys left to make transition easier - might could make them variables outside this map so that we can reference
  // a value inside the map for example $app-link-text
  // but again left in to make transition easier because they were used in the mixin
  'success': #14ac2d,
  'primary': #574f77,
  'primary-light': #e8e0f0,
  'intermediary': #ec991a,
  'attention': #ec991a,
  'neutral': #cccccc,
  'warn': #e61414,
  'primary-icon': #817b99,
  // grays
  'dark-gray': #6d6d6d,
  'medium-gray': #9f9f9f,
  'light-gray': #cccccc,
  'medium-light-gray': #e6e6e6,
  'extra-light-gray': #f2f2f2,
  'warm-gray': #f3f2ec,
  // rmt dark
  'rmt-dark': #574f77,
  'rmt-dark-tint-1': #817b99,
  'rmt-dark-tint-2': #aba7bb,
  'rmt-dark-tint-3': #d5d3dd,
  // rmt violet
  'rmt-violet': #845991,
  'rmt-violet-tint-1': #a383ad,
  'rmt-violet-tint-2': #c2acc8,
  'rmt-violet-tint-3': #e8e0f0,
  // accent
  'accent-aqua': #9cd4ca,
  'accent-aqua-light': #e7f4f2,
  'accent-blue': #6fb9d8,
  'accent-green': #92d050,
  'accent-navy': #002060,
  'accent-salmon': #d47289,
  'accent-teal': #2e6b7e,
  'accent-teal-light': #cbdadf,
  'accent-yellow': #ffc80f,
  'accent-gold': #d5b765,
  'accent-bronze': #ba9364,
  'accent-orange': #ed7422,
  // status
  'status-green-dark': #14ac2d,
  'status-green-light': #e6f5e9,
  'status-orange-dark': #ec991a,
  'status-orange-text': #5e3d0a,
  'status-orange-light': #fff2e0,
  'status-red-dark': #e61414,
  'status-red-light': #ffe9eb,
  // other
  'white': #ffffff,
  'dark-text': rgba(0, 0, 0, $black-text-opacity)
);

$app-white: map-get($app-colors-map, 'white');
$app-dark-text: map-get($app-colors-map, 'dark-text');
$app-link-text: #169bd5;

$color-transparent-white: rgba(255, 255, 255, 0);
$color-transparent-black: rgba(0, 0, 0, 0);

$app-border-radius: 0.5rem;
$app-border-width: 1px;
$app-border-props: $app-border-width solid map-get($app-colors-map, 'medium-gray');
