/* You can add global styles to this file, and also import other style files */
@import 'styles/theming';
@import 'styles/angular.material';
@import 'styles/variables';

// External styles and branding
@import 'styles/external/verikai';

@import 'styles/anchors';
@import 'styles/banner';
@import 'styles/breadcrumbs';
@import 'styles/buttons';
@import 'styles/card';
@import 'styles/colors';
@import 'styles/dashboard';
@import 'styles/datatables';
@import 'styles/dialogs';
@import 'styles/fonts';
@import 'styles/form-lib';
@import 'styles/inprogressindicator';
@import 'styles/layout';
@import 'styles/ocroverlay';
@import 'styles/pill';
@import 'styles/site-filter';
@import 'styles/structural';
@import 'styles/shame';
@import 'styles/text';
@import 'styles/workspace';

* {
  box-sizing: border-box;
}

// Custom
html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;
  color: $app-dark-text;

  [tabindex='-1']:focus {
    outline: none;
  }

  font-family: 'Source Sans Pro', sans-serif;
  font-variant-numeric: lining-nums; // fixes numbers to be aligned with letters
}

a:focus,
button:focus {
  outline: none;
}

a {
  color: $app-link-text;
}

a.mat-mdc-raised-button {
  color: #333333;
}
// For checkbox in data-table header row
.no-margin-label label {
  margin: 0;
}

.form-group-array-item {
  &.cdk-drag {
    &.cdk-drag-preview {
      background-color: map-get($app-colors-map, 'white');
    }
  }
}

.full-width {
  width: 100%;
}

.query-input-menu {
  max-width: none;
}

.button-width-small {
  max-width: 100px;
}

.button-width-large {
  max-width: 200px;
}

.mat-mdc-button.button-text-no-overflow {
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.rmt-dropdown-title {
  box-shadow: 0 -1.25rem 0 32px map-get($app-colors-map, 'rmt-dark');
  color: white;
  background: map-get($app-colors-map, 'rmt-dark');
  padding: 0 1rem;
}

.rmt-button-group > button {
  margin-right: 0.2em;
  margin-left: 0.2em;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

// Generic loading overlay that can be used within cards
.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Added to help a Swagger UI collision
.swagger-ui {
  .col {
    width: auto !important;
  }

  .scheme-container {
    background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

// Special styles to increase the readability of disabled input
// values. See issue #255 for more information
.mat-form-field-disabled {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-mdc-input-element,
  .mat-select-value-text,
  .mat-mdc-chip {
    color: map-get($app-colors-map, 'dark-gray');
  }
  .mat-mdc-input-element {
    -webkit-text-fill-color: map-get($app-colors-map, 'dark-gray');
  }
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .mat-mdc-chip.mat-chip-disabled {
    opacity: 1;
  }
}

// Fix to remove the autofill background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

// This pushes zendesk button out of sight of the user
#launcher {
  bottom: -9999px !important;
}

// Site-wide styles for the user menu
.user-menu {
  a {
    // TODO: this is the desired style for all links on this site; let's elevate this.
    text-decoration: none;
  }
}

fieldset {
  border-radius: 0.25rem;
}

.display-item {
  min-height: 3rem;
  padding: 0.25rem;
}

.rmt-value {
  font-size: 0.875rem;
}

// TODO: this is not the best file to store this in. Maybe _text.scss? Or _workspace.scss?
.rmt-label {
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.125rem;
  white-space: nowrap;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}
