@use '@angular/material' as mat;
@import 'styles/variables';
@import 'styles/theming/_theming.variables.scss';

a.mat-mdc-menu-item {
  color: mat.get-color-from-palette($app-foreground, text);
  &:hover {
    text-decoration: none;
    color: mat.get-color-from-palette($app-foreground, text);
  }
}

a.top-bar-link {
  color: white !important;
}

.ql-theme {
  a.top-bar-link {
    &:hover {
      text-decoration: none;
      color: mat.get-color-from-palette($ql-accent-palette) !important;
    }
  }

  a.sub-nav-item.active {
    background: mat.get-color-from-palette($ql-accent-palette) !important;
  }
}

.ql-rx-theme {
  a.top-bar-link {
    &:hover {
      text-decoration: none;
      color: mat.get-color-from-palette($ql-rx-accent-palette) !important;
    }
  }

  a.sub-nav-item.active {
    background: mat.get-color-from-palette($ql-rx-accent-palette) !important;
  }
}

.sl-theme {
  a.top-bar-link {
    &:hover {
      text-decoration: none;
      color: mat.get-color-from-palette($sl-accent-palette) !important;
    }
  }

  a.sub-nav-item.active {
    background: mat.get-color-from-palette($sl-accent-palette) !important;
  }
}

.rm-theme {
  a.top-bar-link {
    &:hover {
      text-decoration: none;
      color: mat.get-color-from-palette($rm-accent-palette) !important;
    }
  }

  a.sub-nav-item.active {
    background: mat.get-color-from-palette($rm-accent-palette) !important;
  }
}
