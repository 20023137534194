.hidden {
  display: none;
}

.dib {
  display: inline-block;
}

.clickable {
  cursor: pointer;
}
