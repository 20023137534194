@use '@angular/material' as mat;
@import 'styles/variables';
@import 'styles/theming/_theming.variables.scss';

.rmt-subheader {
  font-size: 18px;
  font-weight: 100;
  margin: 0;
  padding-top: 0.5rem;
}

.text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}

.text-center-important {
  text-align: center !important;
}

.code-block {
  background: mat.get-color-from-palette($app-background, unselected-chip);
  color: mat.get-color-from-palette($app-foreground, text);
  font-family: monospace;
  padding: 10px;
  margin: 0 17px;
  border: solid 1px mat.get-color-from-palette($app-background, selected-disabled-button);

  white-space: pre-wrap;
  word-wrap: break-word;
}

.helper-text {
  font-size: 0.75rem;
}

.info-text {
  color: map-get($app-colors-map, 'light-gray');
  opacity: 0.65;
  font-size: 0.875rem;
  font-weight: 600;
}

.font-size-10 {
  font-size: 10px;
}

.text-no-overflow-no-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.text-white-space-pre-wrap {
  white-space: pre-wrap;
}

.text-white-space-nowrap {
  white-space: nowrap;
}

// Used by TextOverflowComponent
.text-no-overflow {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

//// Used by TextOverflowComponent
.text-no-overflow-with-rows {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  // box-orient is not in the CSS spec roadmap, but still works in all our supported
  // browsers. It's recommended that we avoid using this whenever possible. This is an
  // exception.
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-break-at-new-line {
  white-space: pre-line;
}

.single-line-tool-tip {
  max-width: unset !important;
  white-space: nowrap;
}
