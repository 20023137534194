@import 'styles/variables';

.in-progress-indicator {
  position: relative;
  display: block;

  .indicator-container {
    display: grid;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-items: center;
    background: rgba(0, 0, 0, 0.15);
    z-index: 5;
    border-radius: 0.5rem;
    &:after {
      content: ' ';
      display: block;
      width: 2.25rem;
      height: 2.25rem;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $app-white;
      border-color: $app-white transparent $app-white transparent;
      animation: dual-ring 1.2s linear infinite;
    }
  }
}

a.in-progress-indicator,
button.in-progress-indicator {
  .indicator-container:after {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
