@import 'styles/variables';

.field-disable .inline-label .label-text {
  background-color: map-get($app-colors-map, 'medium-light-gray');
}

.mat-mdc-form-field-bottom-align::before {
  display: inline !important;
}

// For inline labels in forms
.inline-label {
  .mat-mdc-form-field-infix {
    padding: 0 0 0.375rem 0.5rem;
    border-top: 0;
    line-height: 1.375rem;
    min-height: 28px;
  }
  .mat-mdc-input-element {
    margin-bottom: -0.125rem;
  }
  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-subscript-wrapper {
    margin-top: 0;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: 1px 0;
  }
}

.dense-layout .inline-label .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 0.5rem;
}

.dense-layout .inline-label .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  margin-bottom: 0.25rem;
}

.mat-slide-toggle.mat-disabled {
  opacity: 1;
  .mat-slide-toggle-bar {
    opacity: 0.38;
  }
}

.file-upload-success {
  border: dashed map-get($app-colors-map, 'status-green-dark') 2px;
  border-radius: 0.25rem;
  width: calc(100% - 1rem);
  min-height: 75px;
  font-size: 1.25rem;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: map-get($app-colors-map, 'status-green-light');
  i,
  fa-icon {
    font-size: 2rem;
  }
  .cursor-click {
    cursor: pointer;
    font-size: 1rem;
  }
  .file-success {
    font-size: 1.5rem;
  }
}

// for the app-collapsible-form app-simple-form material overrides
.app-container {
  .collapsible-form,
  .simple-form {
    &.dense {
      // general form field padding update
      .mat-mdc-form-field {
        padding-bottom: 1.25rem;
      }
      // bring the underline up
      app-date-field {
        .mat-mdc-form-field {
          // pull the text field back down
          .mat-datepicker-input {
            margin-top: 0.25rem;
          }
          margin-top: -0.25rem;
        }
      }

      // allow the button preview to fit in the field
      .ngx-mat-color-toggle {
        button {
          &.mat-mdc-icon-button {
            padding-top: 0.1rem;
            height: 1rem;
          }
        }
      }

      // form group array specific
      app-table-array {
        .field-renderer-cell {
          .mat-mdc-form-field {
            margin-top: 8px;
            padding: 0px;
            .mat-mdc-form-field-subscript-wrapper {
              margin-bottom: 17px;
            }
          }
          .mdc-text-field {
            background-color: unset;
          }
        }
      }
    }
  }
}

// for the multi-select fields, expanding the panel beyond the field width
.app-container {
  .cdk-overlay-container .cdk-overlay-pane {
    .multi-select-filter-panel.mat-mdc-select-panel {
      min-width: 25rem;
      max-height: 30rem;
    }

    .multi-select-filter-panel {
      padding-bottom: 0;
      height: 15rem;

      mat-option {
        min-height: 2rem;
        width: auto;
      }

      &.mdc-menu-surface--open {
        display: inline-table;
      }
    }
  }

  cdk-virtual-scroll-viewport {
    height: 15rem;
  }
}
